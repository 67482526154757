import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

const NavLinks = () => {
    const location = useLocation();

    const handleHomeClick = () => {
        // If already on the Home page, scroll to top manually
        if (location.pathname === "/") {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <nav className="nav-links">
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                smooth
                to="/"
                onClick={handleHomeClick}
            >
                Home
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                smooth
                to="/#services"
            >
                Services
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                smooth
                to="/#about"
            >
                About
            </HashLink>
            <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                to="/contact#contact"
            >
                Contact
            </HashLink>
        </nav>
    );
};

export default NavLinks;
