import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import Notiflix from 'notiflix';
import { FaCheckCircle } from 'react-icons/fa'; // Importing a check icon

const Contact = () => {
    useDocTitle('Tecnobij | Send us a message');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const clearErrors = () => setErrors({});

    const clearInput = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    const validateForm = () => {
        const newErrors = {};
        if (!firstName) newErrors.first_name = "First Name is required.";
        if (!lastName) newErrors.last_name = "Last Name is required.";
        if (!email) newErrors.email = "Email is required.";
        if (!phone) newErrors.phone_number = "Phone number is required.";
        if (!message) newErrors.message = "Message is required.";
        return newErrors;
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('email', email);
        formData.append('phone_number', phone);
        formData.append('message', message);
        formData.append("access_key", process.env.REACT_APP_ACCESS_KEY);

        try {
            const response = await fetch(process.env.REACT_APP_API_URL, {
                method: "POST",
                body: formData
            });

            const data = await response.json();

            if (data.success) {
                clearInput();
                openModal(); // Open modal on successful form submission
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to open the modal and set a timer to close it
    const openModal = () => {
        setIsModalOpen(true);
        setTimeout(() => {
            setIsModalOpen(false);
        }, 3000); // Modal will disappear after 3 seconds
    };

    return (
        <>
            <NavBar />
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <form onSubmit={sendEmail}>
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Send us a message</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <label htmlFor="first_name" className="text-gray-700">First Name*</label>
                                    <input
                                        id="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                </div>
                                <div>
                                    <label htmlFor="last_name" className="text-gray-700">Last Name*</label>
                                    <input
                                        id="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                </div>
                                <div>
                                    <label htmlFor="email" className="text-gray-700">Email*</label>
                                    <input
                                        id="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                </div>
                                <div>
                                    <label htmlFor="phone_number" className="text-gray-700">Phone*</label>
                                    <input
                                        id="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="tel"
                                        placeholder="Phone*"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                                </div>
                            </div>
                            <div className="my-4">
                                <label htmlFor="message" className="text-gray-700">Message*</label>
                                <textarea
                                    id="message"
                                    placeholder="Message*"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                />
                                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                            </div>
                            <button type="submit" disabled={loading} className="w-full bg-blue-900 text-white p-3 rounded-lg hover:bg-blue-800 focus:outline-none focus:shadow-outline transition-colors duration-300">
                                {loading ? 'Loading...' : 'Send Message'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Payment Success Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 w-96 shadow-lg text-center">
                        <FaCheckCircle className="text-green-500 w-16 h-16 mx-auto mb-4" />
                        <h2 className="text-xl font-bold">Success!</h2>
                        <p className="text-gray-700">Message sent successfully!</p>
                    </div>
                </div>
            )}

            <Footer />
        </>
    );
};

export default Contact;
