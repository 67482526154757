import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">

                    {/* Top area: Blocks */}
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">

                        {/* 1st block */}
                        <div className="col-span-12 lg:col-span-4 w-80">
                            <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-full mx-auto">
                                <h3 className="font-bold text-4xl mb-4">TECNOBIJ</h3>
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>Tecnobij Solutions</h5>
                                    <p>Pune</p>
                                    <p>Maharashtra</p>
                                    <p>+91 7620638650</p>
                                    <p>contact@tecnobij.com</p>
                                </div>
                            </div>
                        </div>

                        {/* 2nd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">About</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Contact</HashLink>
                                </li>
                            </ul>
                        </div>
                        {/* 3rd block */}
                        <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                            <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES</h6>
                            <ul className="text-md">
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Web Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Mobile App Development</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Domain and Hosting</Link>
                                </li>
                                <li className="mb-2">
                                    <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">General IT Consultations</Link>
                                </li>
                            </ul>
                        </div>

                        {/* 4th block */}
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-xl mb-6">
                                Social Media Links.
                            </div>

                            <div className="text-md font-medium mb-6">
                                Follow us on social media.
                            </div>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    {/* Twitter */}
                                    <li>
                                        <a href="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                            </svg>
                                        </a>
                                    </li>

                                    {/* Facebook */}
                                    <li className="ml-4">
                                        <a href="#" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                            </svg>
                                        </a>
                                    </li>

                                    {/* Instagram */}
                                    <li className="ml-4">
                                        <a href="https://www.instagram.com/tecnobij/" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Instagram">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16 10.878c-2.82 0-5.122 2.302-5.122 5.122 0 2.82 2.302 5.122 5.122 5.122 2.82 0 5.122-2.302 5.122-5.122 0-2.82-2.302-5.122-5.122-5.122zm0 8.27c-1.738 0-3.148-1.41-3.148-3.148 0-1.738 1.41-3.148 3.148-3.148s3.148 1.41 3.148 3.148c0 1.738-1.41 3.148-3.148 3.148zM21.697 9.503c-.657 0-1.192.535-1.192 1.192 0 .657.535 1.192 1.192 1.192.657 0 1.192-.535 1.192-1.192 0-.657-.535-1.192-1.192-1.192zM16 8.142c-2.17 0-2.448.008-3.307.048-.86.04-1.448.176-1.96.377-.53.207-1.018.47-1.465.86-.5.447-.703.935-.86 1.465-.201.512-.338 1.1-.377 1.96-.04.86-.048 1.137-.048 3.307s.008 2.448.048 3.307c.04.86.176 1.448.377 1.96.207.53.47 1.018.86 1.465.447.5.935.703 1.465.86.512.201 1.1.338 1.96.377.86.04 1.137.048 3.307.048s2.448-.008 3.307-.048c.86-.04 1.448-.176 1.96-.377.53-.207 1.018-.47 1.465-.86.5-.447.703-.935.86-1.465.201-.512.338-1.1.377-1.96.04-.86.048-1.137.048-3.307s-.008-2.448-.048-3.307c-.04-.86-.176-1.448-.377-1.96-.207-.53-.47-1.018-.86-1.465-.447-.5-.935-.703-1.465-.86-.512-.201-1.1-.338-1.96-.377-.86-.04-1.137-.048-3.307-.048zm.083 2.66c2.878 0 5.11 2.298 5.11 5.094 0 2.78-2.232 5.093-5.11 5.093-2.868 0-5.094-2.313-5.094-5.093 0-2.796 2.226-5.094 5.094-5.094z" />
                                            </svg>
                                        </a>
                                    </li>

                                    {/* LinkedIn */}
                                    <li className="ml-4">
                                        <a href="https://www.linkedin.com/company/tecnobij" className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="LinkedIn">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M26.615 0h-21.23C2.658 0 0 2.658 0 5.385v21.231C0 29.342 2.658 32 5.385 32h21.231C29.342 32 32 29.342 32 26.615V5.385C32 2.658 29.342 0 26.615 0zm-14.662 25.853h-3.938V12.465h3.938v13.388zM11.38 10.647c-1.252 0-2.264-1.01-2.264-2.264 0-1.253 1.01-2.264 2.264-2.264 1.253 0 2.263 1.01 2.263 2.264 0 1.254-1.01 2.264-2.263 2.264zm16.535 15.206h-3.938V18.7c0-1.935-.035-4.427-2.699-4.427-2.698 0-3.113 2.104-3.113 4.278v6.568h-3.938V12.465h3.785v1.83h.053c.524-1 1.803-2.059 3.71-2.059 3.97 0 4.693 2.604 4.693 6.003v7.615z" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Bottom area */}
                    <div className="text-center">
                        <p className="text-sm text-gray-500">
                            &copy; {new Date().getFullYear()} Technobij Solutions. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
