import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import technobij from '../../images/Tecnobij_Logo.png';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [top, setTop] = useState(true);
    const [scrollingDown, setScrollingDown] = useState(false);
    const [showNav, setShowNav] = useState(true);
    let lastScrollY = window.scrollY;

    useEffect(() => {
        const scrollHandler = () => {
            if (window.scrollY > lastScrollY) {
                setScrollingDown(true);
            } else {
                setScrollingDown(false);
            }

            lastScrollY = window.scrollY;
            setTop(window.pageYOffset <= 10);

            // Show the navbar if we're at the bottom of the page
            const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
            setShowNav(isBottom || !scrollingDown);
        };

        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [scrollingDown]);

    return (
        <nav className={`fixed top-0 left-0 w-full transition-all duration-300 ease-in-out z-50 ${
            scrollingDown && !showNav ? '-translate-y-full' : 'translate-y-0'
        } ${!top ? 'bg-white shadow-md' : 'bg-transparent'}`}>
            <div className="flex justify-between items-center px-4 h-full">
                {/* Logo and Title Section */}
                <div className="flex items-center space-x-2">
                    <img src={technobij} alt="Technobij Logo" className="h-8 w-8" />
                    <HashLink smooth to="/#hero">
                        <h1 className="font-extrabold text-2xl text-sky-500">Tecnobij</h1>
                    </HashLink>
                </div>

                {/* Mobile Menu Button */}
                <div className="lg:hidden">
                    <button className="p-1 rounded-md text-blue-900" onClick={() => setIsOpen(!isOpen)}>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen ? (
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                                />
                            ) : (
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z" />
                            )}
                        </svg>
                    </button>
                </div>

                {/* Desktop Nav Links */}
                <div className="hidden lg:flex space-x-4">
                    <NavLinks />
                </div>

                {/* Mobile Menu */}
                <div
                    className={`fixed top-12 left-0 w-full bg-white shadow-lg rounded-md lg:hidden transition-all transform duration-300 z-40 ${
                        isOpen ? 'block' : 'hidden'
                    }`}
                >
                    <div className="flex flex-col space-y-3 p-3">
                        <NavLinks />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
